#login-page {
  margin-bottom: 0;
  background-color: var(--color-background);
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#login {
  width: 60%;
  max-width: 450px;
  min-width: 350px;
}

#login form {
  width: 90%;
  margin-bottom: 28px;
}

#login a {
  text-decoration: underline;
  font-size: 14px;
}

a.forgot-password-link {
  display: block;
  margin-bottom: 10px;
}

.sign-up-link {
  font-size: 14px;
}

#login-page h1 {
  font-size: 2rem;
}

#login h5 {
  font-size: 0.9rem;
  font-family: 'galano-semibold', sans-serif;
}

#login p {
  text-align: start;
}

.asset-square {
  width: 80%;
  max-width: 350px;
  position: absolute;
  bottom: 90px;
  left: -30px;
}