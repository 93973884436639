.carousel {
  display: flex;
  justify-content: space-between ;
  align-items: center;
  /* height: 500px; */
}

.carousel i.icon {
  font-size: 3em;
  color: var(--color-brown);
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s;
  margin: 0 20px;
}

.carousel i.icon:hover {
  background-color: white;
}

.carousel-img-wrapper {
  display: flex;
  justify-content: space-between;
  height: 400px;
}

.carousel-img-wrapper img {
  max-width: 280px;
  width: 20%;
  object-fit: contain;
}

.carousel-middle {
  transform: scale(3);
  transition: all 1s;
}

@media only screen and (max-width: 700px) {
  .carousel-middle {
    transform: scale(4);
    transition: all 1s;
  }
}

@media only screen and (max-width: 500px) {
  .carousel-img-wrapper {
    height: 300px;
  }
}