.landing-component.secondary {
  background-color: var(--color-secondary-background);
}

.landing-title {
  text-align: center;
  color: var(--color-grey-1);
  margin-bottom: 50px;
}

.quote {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 100px 0;
}

.quote h4 {
  width: 60%;
  min-width: 280px;
  text-align: center;
  font-weight: 300;
  line-height: 1.6em;
  font-size: 1.3rem;
}

.quote h3 {
  margin-top: 20px;
  font-size: 1.5rem;
  font-family: 'Nothing You Could Do', cursive;
  color: var(--color-secondary-dark);
}

.quote .strike-through {
  text-decoration: line-through;
}

.quote .underline {
  text-decoration: underline;
}

.services {
  margin-bottom: 100px;
  position: relative;
}

.service-wrapper {
  position: relative;
  padding: 0 20px; 
}

.reviews {
  padding: 0 10px;
  padding-bottom: 100px;
}

.reviews-body {
  display: flex;
  align-items: center;
}

.reviews.secondary {
  background-color: var(--color-secondary-background);
}

.reviews-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  overflow: hidden;
}

/* blogs */

.blogs {
  max-width: 900px;
  margin: auto;
}

.blogs-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* locations  */

.locations {
  max-width: 500px;
  margin: 100px auto;
}

/* how it works  */

.how-it-works {
  padding-bottom: 100px;
  position: relative;
}

.how-it-works-card-wrapper {
  position: relative;
}

/* pricing  */

.pricing-wrapper {
  position: relative;
}

.pricing {
  max-width: 600px;
  width: 90%;
  margin: auto;
  margin-bottom: 100px;
  position: relative;
}

.pricing-heading h4 {
  text-decoration: underline;
  text-align: center;
  margin: 0;
}

.pricing-heading p {
  padding-top: 20px;
}

.pricing-cards-wrapper {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

/* benefits  */
.benefits {
  padding: 0 10px;
  padding-bottom: 100px;
}

.benefits-body {
  display: flex;
  align-items: center;
}

.benefits-cards-wrapper {
  margin-top: 40px;
  display: flex;
  overflow: hidden;
}

/* why us */
.why-us {
  padding-bottom: 100px;
}

/* faqs */
.faqs {
  margin-bottom: 100px;
}

.faqs-wrapper {
  width: 90%;
  margin: auto;
  max-width: 600px;
}

/* pelvic journey  */

.pelvic-journey {
  position: relative;
  margin-bottom: 120px;
}

.pelvic-journey-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 650px;
  margin: auto;
  padding: 0 20px;
}

.vertical-divider {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: calc(100% - 50px);
  top: 50px;
}

/* upcoming events  */
.upcoming-events {
  width: 90%;
  max-width: 580px;
  margin: 120px auto 100px auto;
}

.upcoming-events h4 {
  border: 1px solid var(--color-grey-2);
  text-align: center;
  padding: 10px;
}

.upcoming-events .card-wrapper {
  justify-content: center;
}

/* why pelvic  */

.why-pelvic h2 {
  margin-top: 100px;
}

/* blob  */

.blob {
  position: absolute;
  top: 40px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.blob.bottom {
  top: 180px;
}

.blob-wrapper {
  -webkit-transform: translateX(15%);
  -moz-transform: translateX(15%);
  -ms-transform: translateX(15%);
  -o-transform: translateX(15%);
  transform: translateX(15%);
  display: block;
  width: 100%;
  height: 100%;
}

.blob-inner {
  background-color: var(--color-background-primary);
}

.blob-inner::before {
  width: 150vw;
  height: 150vw;
  top: 5.25vw;
  right: -25vw;
  bottom: auto;
  left: auto;
  transform-origin: 50% 50%;
  border-radius: 43%;
  animation: drift 30000ms infinite linear;
  content: "";
  position: absolute;
  background-color: var(--color-background-primary);
}

.blob-inner::after {
  height: auto;
  top: 75vw;
  right: -50%;
  bottom: 0;
  left: -50%;
  content: "";
  position: absolute;
  background-color: var(--color-background-primary);
}
.blob-inner.secondary,
.blob-inner.secondary::before,
.blob-inner.secondary::after {
  /* background-color: #EFCBB9; */
  background-color: #F4E0D4;
}

@keyframes drift {
  from {
    transform: rotate(0deg)
  }

  from {
    transform: rotate(360deg)
  }
}

@media only screen and (max-width: 750px) {
  .blob {
    top: 100px;
  }
}