.ui.active.modal {
  width: 100%;
  max-width: 550px;
}

.subscribe-modal h3 {
  font-size: 1.7rem;
  margin-bottom: 30px;
  color: white;
  text-align: center;
}

.subscribe-modal h5 {
  color: white;
  margin-bottom: 30px;
}

#career-subscribe-modal h3,
#career-subscribe-modal h5 {
  color: black;
}

#course-subscribe-modal {
  background-color: var(--color-navy);
  padding: 40px 50px;
}

#career-subscribe-modal {
  background-color: var(--color-backgroundBrown);
  padding: 40px 50px;
}

.form-wrapper {
  width: 80%;
  margin: auto;
}

@media only screen and (max-width: 550px) {
  #subscribe-modal h3 {
    font-size: 1.4rem;
  }

  #subscribe-modal h5 {
    font-size: 1rem;
  }

  #subscribe-modal {
    padding: 30px !important;
  }
}