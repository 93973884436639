#learning {
  padding-top: 120px;
}

#learning h1 {
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
}

/* course series */

#learning-main {
  margin-top: 130px;
}

#learning-main iframe {
  height: 500px;
  width: 100%;
  background: black;
}

.learning-nav-title h1 {
  font-size: 1.3rem;
  color: white;
  margin-bottom: 5px;
}

.learning-nav-title p {
  color: white;
}

.learning-nav h1 {
  font-size: 1.3rem;
  margin-top: 20px;
  margin-left: 20px;
  color: white;
  font-weight: 700;
}

/* course  */

.learning-nav {
  position: absolute;
  top: 70px;
  width: 100vw;
  min-height: 60px;
  background-color: #000000ab;
}

/* learning overview  */

#learning-overview {
  margin: 50px auto;
  max-width: 800px;
  width: 90%;
}

#learning-overview ul {
  padding-left: 20px;
}

#learning-overview li {
  list-style-type: square;
  list-style-position: outside;
  margin-bottom: 10px;
  font-size: 0.9rem;
}

#learning-overview p {
  font-size: 0.9rem;
  line-height: 1.3rem;
}

#learning-overview h3 {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 1.4rem;
  letter-spacing: 1px;
  font-weight: 600;
}

#learning-overview h5 {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 1rem;
  letter-spacing: 1px;
  font-family: 'galano-semibold', sans-serif;
}

.ebook img {
  width: 100px;
  margin-bottom: 20px;
}

.learning-section {
  margin-top: 40px;
}

/* instructor bio */
.instructor-bio-body {
  display: flex;
  align-items: center;
}

.instructor-bio-body img {
  width: 100px;
  object-fit: cover;
  border-radius: 15px;
  margin-right: 20px;
}

#learning-overview p.instructor-bio-name {
  margin-bottom: 0px;
  font-family: var(--font-logo);
  font-weight: 600;
  font-size: 1.1rem;
}

.instructor-bio-title {
  margin-bottom: 10px;
  font-size: 0.8rem;
}

#learning-overview .instructor-bio-description {
  font-size: 0.9rem;
  margin-bottom: 2px;
  line-height: 1.5em;
}

/* Course Card */

.course-card-img {
  width: 100%;
  height: 170px;
  border-bottom: 1px solid rgba(0,0,0,.15);
  position: relative;
  /* box-shadow: 0 0 0 1px rgba(0,0,0,.15); */
}

.course-card-duration {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: rgba(26, 46, 59, 0.85);
  color: white;
  font-family: 'galano-light';
  font-size: 0.7rem;
  padding: 5px;
  border-radius: 2px;
  letter-spacing: 1px;
}

.course-card-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.course-card-detail {
  padding: 20px;
}

.course-card-title {
  overflow: hidden;
  overflow: hidden;
  height: 55px;
}

.course-card-detail h2 {
  font-size: 1rem;
}

.course-card-detail p {
  font-size: 0.85rem;
}