.marquee {
  height: 52px;
  white-space: nowrap;
  animation: marquee 20s linear infinite;
  overflow: hidden;
  width: 1700px;
  position: absolute;
  bottom: 0;
  z-index: 100;
}

.marquee h3 {
  padding: 0 80px;
  display: inline;
  font-weight: 900;
  text-decoration: underline;
  font-size: 1.3rem;
  color: var(--color-primary-main);
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}