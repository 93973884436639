#header {
  max-width: 100%;
  height: 65px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: var(--color-background-primary);
  padding-left: 20px;
  padding-right: 20px;
  transition: all 0.5s;
}

#header.no-background {
  background-color: transparent;
  transition: all 0.5s;
}

.logo a {
  color: var(--color-primary-dark);
  font-size: 1.3rem;
  font-family: 'Playfair Display', serif;
}

.logo a:hover {
  color: var(--color-primary-main);
}

#nav {
  display: flex;
  align-items: center;
}

#nav a,
#nav form {
  margin-left: 10px;
  margin-right: 10px;
}

#mobile-nav button {
  width: 70px;
  height: 70px;
}

#nav-side-list {
  padding: 10%;
  text-align: center;
}

#nav-side-list-logo {
  margin-bottom: 20px;
}

#nav-side-list-logo a {
  color: black;
  font-size: 1.5em;
}

#nav-side-list-logo a:hover {
  color: var(--color-navy);
}

#social-media {
  display: inline-block;
  position: fixed;
  left: 10px;
  bottom: 20px;
  z-index: 100;
}

#social-media a {
  display: block;
  margin-bottom: 5px;
}

#social-media i {
  color: var(--color-navy);
}

/* .MuiDrawer-paper {
  width: 80% !important;
} */
