.baby-massage-hero {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 90px auto;
}

.baby-massage-hero-body {
  flex-direction: column;
}

.list-with-icon {
  display: flex;
  margin: 8px 0;
}


#course-secrets {
  text-align: center;
  width: 90%;
  margin: 100px auto;
}

#course-secrets p {
  line-height: 1.7rem;
  margin-bottom: 20px;
  text-align: left;
}

/* course details  */
#course-detail {
  max-width: 1100px;
  width: 90%;
  margin: 0 auto;
}

#course-detail-title {
  display: flex;
  flex-direction: column;
}

#course-detail-title h3 {
  text-align: center;
  padding: 0 30px;
  font-weight: 700;
}

#course-detail-title h4 {
  color: var(--color-primary-light);
}

.course-detail-body {
  width: 50%;
  min-width: 320px;
  margin: 20px 0;
}

#course-detail img {
  min-width: 280px;
  width: 50%;
  object-fit: contain;
}

#course-detail .list-with-icon {
  width: 90%;
  margin: 20px auto;
}

#course-conversion .material-icons.MuiIcon-root,
#course-detail .material-icons.MuiIcon-root {
  color: var(--color-darkGray);
  margin-right: 10px;
  font-size: 18px;
}

#course-detail button {
  margin: 20px auto;
  display: block;
}

.flex-end {
  align-self: flex-end;
}

.baby-101-review {
  max-width: 550px;
  margin: 40px auto 0 auto;
  display: block;
  width: 90%;
}

.baby-101-problem {
  width: 100%;
  object-fit: contain;
  margin: 40px auto;
  max-width: 700px;
  display: block;
}

/* mama review */

#baby-course-review {
  align-items: center;
  max-width: 1100px;
  width: 90%;
  margin: 100px auto;
  justify-content: space-between;
  text-align: center;
}

#baby-course-review h3 {
  width: 40%;
  color: var(--color-lightBlue);
  font-weight: 700;
}

#baby-course-review .divider {
  background-color: var(--color-yellow);
}

.baby-course-review-body {
  width: 60%;
}

#baby-course-review img {
  width: 55px;
  object-fit: contain;
}

#baby-course-review p {
  line-height: 1.5rem;
}

#baby-course-review h5 {
  margin-top: 20px;
}

/* course conversion */

#course-conversion {
  max-width: 1100px;
  width: 90%;
  margin: 100px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

#baby-demo {
  width: 50%;
  min-width: 250px;
  height: 320px;
  margin-bottom: 0;
}

#course-conversion p {
  margin: 0;
}

#course-conversion h3 {
  font-weight: 700;
  color: var(--color-lightBlue);
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;
}

#course-conversion button {
  margin-top: 20px;
}

/* faq */

#course-faq {
  max-width: 700px;
  width: 90%;
  margin: 70px auto;
}

#course-faq h3 {
  text-align: center;
  color: var(--color-lightBlue);
  font-weight: 700;
}

#course-faq .divider-thin {
  margin: 0 auto 40px auto;
}

#course-question {
  max-width: 900px;
  margin: auto;
  width: 90%;
  display: flex;
  justify-content: center;
}

#course-question img {
  width: 25%;
  object-fit: contain;
}

#course-question-form {
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  padding: 30px;
  min-width: 300px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  margin: auto;
}

#course-question-form h3 {
  color: var(--color-lightBlue);
  font-weight: 700;
  text-align: center;
}

#course-question-form .divider-thin {
  margin: 0 auto 30px auto;
}

#course-question-form button {
  margin-top: 20px;
}

/* feedback carousel */

#feedback {
  padding: 60px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

#feedback h3 {
  font-weight: 700;
  color: var(--color-lightBlue);
  margin-bottom: 0;
}

.feedback-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.feedback-card {
  width: 90%;
  display: flex;
  align-items: flex-start;
  /* margin: 20px 0; */
  padding: 40px 0;
  border-bottom: 0.5px solid #eee;
}

.feedback-user {
  margin-right: 10px;
  width: 100px;
}

.feedback-review {
  width: calc(100% - 100px);
}

.feedback-review p {
  font-size: 14px;
}

.feedback-user p {
  font-size: 0.9rem;
  font-family: 'Playfair Display', serif;
  color: #777;
  margin-bottom: 5px;
}

.feedback-user .verified {
  color: var(--color-burntOrange);
  font-size: 12px;
  margin-top: 5px;
}

.feedback-user .small {
  color: #aaa;
  font-size: 13px;
}

#feedback .material-icons {
  font-size: 3.5rem;
  width: 1.5em;
  color: #ccc;
  cursor: pointer;
}

#feedback .material-icons:hover {
  color: #444;
}

.pagination-button {
  cursor: pointer;
}

.pagination-button:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 780px) {
  .mid-right {
    margin-left: 0;
    margin-top: 20px;
    width: 15%;
    margin-bottom: 10px;
  }

  #course-conversion {
    justify-content: center;
    flex-direction: column;
  }

  #course-detail img,
  .course-detail-body,
  #course-conversion .landing-component-left {
    width: 90%;
  }

  #course-conversion .landing-component-right {
    margin-top: 20px;
    height: 300px;
    width: 80%;
  }

  #course-question {
    display: initial;
  }

  #course-question img {
    margin: 0 auto 30px auto;
    display: block;
    width: 200px;
  }
}

@media only screen and (max-width: 600px) {
  #baby-course-review {
    justify-content: center;
    margin: 50px auto;
  }

  .baby-course-review-body,
  #baby-course-review h3 {
    width: 90%;
  }

  #course-conversion .landing-component-right {
    height: 240px;
    width: 90%;
  }
}

@media only screen and (max-width: 500px) {
  #course-secrets h3 {
    font-size: 2rem;
  }
}
