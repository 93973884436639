.workshop-landing {
  padding: 120px 20px;
  display: flex;
  flex-wrap: wrap;
}

.workshop-left {
  flex: 1;
  min-width: 200px;
  margin-bottom: 28px;
}

.workshop-landing .upcoming-event-card-img {
  height: auto;
}

.workshop-landing .upcoming-event-card-img img {
  width: 100%;
}

.workshop-item {
  display: flex;
}

.workshop-landing-body {
  margin-left: 16px;
  flex: 1.5;
}

.workshop-landing-body b {
  padding-right: 8px;
}

.workshop-landing-body p {
  margin-bottom: 12px;
}

.workshop-landing h1 {
  font-size: 1.8rem;
}

.workshop-landing h5 {
  color: var(--color-secondary-main);
  margin-bottom: 12px;
  margin-top: 36px;
}

/* workshop form */

.workshop-form {
  max-width: 300px;
}