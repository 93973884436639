#footer {
  margin-top: 180px;
  position: relative;
  padding-top: 60px;
  padding-bottom: 20px;
  background-color: var(--color-primary-dark);
}

.footer-links-wrapper {
  display: flex;
  min-width: 300px;
  justify-content: space-between;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-around;
  width: 100%;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-subscribe {
  width: 50%;
  min-width: 300px;
  margin-bottom: 28px;
}

.footer-subscribe input {
  height: 45px;
}

.footer-subscribe label {
  color: white;
  font-size: 1rem;
  margin: 15px 0;

}

.input-submit {
  display: flex;
  border: 1px solid white;
  height: 45px;
  align-items: center;
}

.input-submit input {
  border: none;
  margin: 0;
}

.input-submit input::placeholder {
  color: white;
}

.input-submit input:active,
.input-submit input:focus {
  border: none;
  box-shadow: none;
}

.input-submit-button {
  font-family: 'galano-reg', sans-serif;
  color: white;
  cursor: pointer;
  padding-right: 10px;
}

#footer a {
  margin: 10px 0;
  z-index: 1;
  color: white;
}

#footer p {
  color: white;
}

.copy-right {
  text-align: center;
  font-size: 12px;
}

#footer a.footer-contact {
  display: block;
  text-align: center;
  cursor: pointer;
}