#home {
  position: relative;
  min-height: 100vh;
}

#home .wave-wrap {
  bottom: 0;
}

#home .wave-wrap a {
  position: absolute;
  bottom: 10px;
  z-index: 1;
  left: 0;
  text-align: center;
  right: 0;
  cursor: pointer;
}

.home-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 95%;
  padding: 24px 0;
  margin: 0 auto;
}

.home-header h2 {
  color: var(--color-primary-dark);
  font-size: 1.3rem;
  margin: 0;
  font-weight: 300;
}

.home-header a {
  color: var(--color-primary-dark);
  text-decoration: underline;
}

.home-body {
  width: 90%;
  max-width: 860px;
  margin: 60px auto 0 auto;
}

.home-body-img {
  width: 60%;
  max-width: 380px;
  margin-top: -30px;
}

.home-body-img img {
  width: 100%;
}

.home-body h3 {
  color: var(--color-primary-main);
  font-family: 'galano-light', sans-serif;
  font-size: 1.6rem;
  line-height: 1.4em;
  margin-bottom: 0;
}

.home-body h4 {
  color: var(--color-secondary-main);
  font-size: 1.8rem;
  margin-top: 20px;
}

.home-body h1 {
  color: var(--color-primary-main);
  font-family: 'galano-light', sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 0.01em;
  font-size: 2.6rem;
}

#home .triple-image-card {
  border: 2px solid var(--color-secondary-light);
  box-shadow: 0 0 5px var(--color-secondary-light);
}

#home .triple-image-card:hover {
  background-color: var(--color-secondary-background);
}

#home .triple-image-card h5 {
  color: var(--color-secondary-main);
}

#home .wave-wrap {
  position: inherit;
}

@media only screen and (max-width: 500px) {
  #home .flex {
    flex-direction: column-reverse;
    align-items: center;
  }
}
