/* Blog Card */
#blog-page .center {
  flex-wrap: wrap;
}

#blog-page h1 {
  text-align: center;
  font-size: 2.3rem;
  margin-top: 100px;
}

.blog-card {
  width: 90vw;
  max-width: 360px;
  height: 350px;
  position: relative;
  margin: 20px;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid var(--color-primary-dark);
  transition: all 0.5s;
}

.blog-card img {
  object-fit: cover;
  height: 58%;
  width: 90%;
  margin: 20px auto;
  display: block;
}

.blog-card-content {
  width: 90%;
  margin: auto;
}

.blog-card-content p {
  margin: 10px 0;
  color: #acacac;
  letter-spacing: .08334rem;
  text-transform: uppercase;
  font-size: 13px;
}

.blog-card h5 {
  transition: all 0.5s;
}

.blog-card:hover h5 {
  text-decoration: underline;
  color: var(--color-secondary-main);
}

.blog-card:hover {
  border: 1px solid var(--color-secondary-main);
}

