.baby-massage-video h1 {
  font-size: 2.6em;
  margin: 60px;
  text-align: center;
}

.baby-massage-video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.baby-massage-video iframe {
  width: 100%;
  height: 450px;
}