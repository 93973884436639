.gift-up-target {
  margin-top: 50px;
  margin-bottom: 50px;
}

#gift-card h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-top: 100px;
}

@media only screen and (max-width: 500px) {
  #gift-card .title {
    padding-top: 0;
    padding-bottom: 0;
  }
}