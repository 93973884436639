#countdown {
  display: flex;
  width: 280px;
  justify-content: space-between;
}

.countdown-display-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.countdown-display {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 3px solid #033f57;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  font-weight: 700;
  color: #033f57;
}

.countdown-display-wrapper p {
  margin-top: 5px;
  color: #033f57;
  font-size: 0.95rem;
}