.react-add-to-calendar {
  margin-top: 16px;
}

.react-add-to-calendar__button {
  color: var(--color-secondary-main);
  text-transform: uppercase;
  cursor: pointer;
  font-family: 'galano-reg', sans-serif;
  text-decoration: underline;
}

.react-add-to-calendar__button:hover {
  color: var(--color-secondary-dark);
  text-decoration: underline;
}

.react-add-to-calendar__dropdown {
  width: 180px;
  margin-top: 8px;
  border: 1px solid var(--color-secondary-main);
  border-bottom: none;
}

.react-add-to-calendar__dropdown li a {
  color: var(--color-secondary-main);
  display: block;
  padding: 12px 8px;
  border-bottom: 1px solid var(--color-secondary-main);
}

.react-add-to-calendar__dropdown li a:hover {
  background-color: var(--color-secondary-light);
}




