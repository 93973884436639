.faq {
  padding: 20px 0;
  cursor: pointer;
  transition: all 0.5s;
}

.faq:hover {
  background-color: var(--color-grey-4);
}

.faq-question {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq .material-icons.MuiIcon-root {
  color: var(--color-gray);
}

.faq h5 {
  margin: 0;
}

.faq p {
  font-size: 0.95rem;
  line-height: 1.6rem;
  margin: 30px 15px 15px 30px;
}

.faq ul {
  margin-left: 15px;
}

.faq li {
  line-height: 1.8em;
}