.double-card,
.triple-image-card {
  border: 2px solid var(--color-primary-light);
  box-shadow: 0 0 5px var(--color-primary-light);
  padding: 30px;
  min-width: 200px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.double-card {
  width: 48%;
}

.double-card h5 {
  font-size: 4rem;
  color: var(--color-primary-main);
}

.double-card h3 {
  color: var(--color-primary-main);
  font-size: 1.9rem;
}

.double-card-price {
  font-size: 1.6rem;
  color: var(--color-primary-light);
}

.triple-image-card {
  width: 30%;
}

.addon-card:hover,
.double-card:hover,
.triple-image-card:hover {
  background: var(--color-primary-hover);
  box-shadow: none;
  transition: all 0.3s; 
}

.double-image-card img,
.triple-image-card img {
  width: 180px;
  margin-bottom: 36px;
}

.double-card:last-of-type,
.triple-image-card:last-of-type {
  margin-right: 0;
}

.double-image-card h5,
.triple-image-card h5 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: var(--color-primary-main);
}

.double-image-card p,
.triple-image-card p {
  color: var(--color-grey-2);
}

.double-card-note {
  color: rgb(115, 112, 115);
  margin-top: 15px;
  font-size: 0.95rem;
}

.double-card-description {
  color: var(--color-primary-light);
  margin-top: 10px;
  font-size: 1.2rem;
}

.addon-card {
  display: flex;
  width: 32%;
  min-width: 240px;
  border: 2px solid var(--color-primary-light);
  padding: 15px 8px 15px 0;
  margin-bottom: 20px;
  cursor: pointer;
}

p.addon-card-duration {
  color: var(--color-primary-light);
  margin-bottom: 12px;
  font-size: 0.95rem;
}

p.addon-card-description {
  font-size: 0.9rem;
  margin-bottom: 18px;
}

.addon-card h6 {
  margin: 0;
  color: var(--color-primary-light);
}

/* service card  */

.service-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
}

.service-card-body {
  width: 540px;
  padding: 48px;
  border: 1px solid;
}

.service-card-body h4 {
  text-align: center;
  color: var(--color-primary-main);
}

.service-card-content {
  margin-bottom: 28px;
}

.service-card-body h5 {
  color: var(--color-primary-light);
  margin-bottom: 6px;
}

.service-card-body p {
  margin-bottom: 20px;
  font-size: 1rem;
}

.service-card button {
  display: block;
  margin: 36px auto 0 auto;
}

.opposite.service-card {
  flex-direction: row-reverse;
}

.opposite.service-card .service-card-img {
  margin-left: 0;
}

.opposite.service-card .service-card-body {
  margin-left: -30px;
}

/* how it works  */

.how-it-works-card {
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.how-it-works-card.opposite {
  flex-direction: row-reverse;
}

.how-it-works-card img {
  margin: 20px;
  max-width: 300px;
  width: 40%;
  display: block;
}

.how-it-works-card-content {
  margin: 20px;
  width: 40%;
  max-width: 500px;
  min-width: 300px;
}

.how-it-works-card h3 {
  font-weight: 700;
  color: var(--color-grey-1);
  font-size: 1.8rem;
  margin-top: -60px;
  margin-left: 40px;
}

.how-it-works-pelvic .how-it-works-card h3 {
  font-family: 'galano-light', sans-serif;
  font-size: 1.3rem;
  line-height: 1.4em;
  letter-spacing: 0.05em;
  color: var(--color-primary-dark);
}

.how-it-works-step {
  font-size: 148px;
  color: var(--color-primary-light);
  font-weight: 900;
}

/* pricing card  */
.pricing-card {
  width: 48%;
  padding: 24px 12px;
  text-align: center;
  border: 1px solid var(--color-grey-2);
  margin-bottom: 40px;
  margin-right: 20px;
}

.pricing-card:last-of-type {
  margin-right: 0;
}

.pricing-card h4 {
  color: var(--color-primary-light);
}

.pricing-card h3 {
  font-size: 3rem;
  color: var(--color-primary-dark);
  font-family: galano-light, sans-serif;
  margin: 0;
}

.pricing-card button {
  margin-top: 20px;
}

/* benefits card */

.benefits-card {
  width: 100%;
  max-width: 260px;
  /* height: 260px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid var(--color-primary-dark);
  padding: 20px;
  background-color: white;
  text-align: center;
  flex-shrink: 0;
  margin-right: 20px;
}

.benefits-card h4 {
  margin-top: 20px;
}

.benefits-card p {
  font-size: 14px;
}

/* why us card  */
.why-us-card {
  display: flex;
  width: 100%;
  margin: auto;
  max-width: 600px;
  align-items: center;
  padding: 10px 0;
}

/* .why-us-card img {
  width: 100px;
} */

.why-us-card h5 {
  margin-bottom: 10px;
  font-size: 1.3rem;
}

.why-us-card p {
  font-size: 1rem;
  margin-bottom: 0.9em;
}

.why-us-card-divider {
  position: relative;
  margin-top: 60px;
}

.why-us-card-divider svg {
  position: absolute;
  top: 0;
  left: 35px;
}

.line {
  margin-top: 100px;
  margin-left: 6px;
  width: 90px;
  border: 1px solid #322030;
  transform: rotate(90.36deg);
}

.line.hidden {
  visibility: hidden;
}

/* review card */

.review-card {
  border: 1px solid var(--color-grey-1);
  flex-shrink: 0;
  width: 100%;
  max-width: 230px;
  height: 400px;
  margin-right: 30px;
}

.review-card.image {
  padding: 10px;
}

.review-card img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.review-card-body {
  padding: 25px 0;
  position: relative;
  height: 310px;
}

.review-card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 225px;
  padding: 0 15px 5px 35px;
  overflow-y: scroll;
}

.review-card-content p {
  height: 98%;
  font-size: 13px;
  font-family: 'galano-light', sans-serif;
  text-align: left;
  line-height: 1.35em;
}

.review-card-footer {
  text-align: center;
}

.review-card-footer p {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 3px;
  font-family: 'galano-reg', sans-serif;
}

.review-card-footer img {
  width: 60px;
}

.review-card-footer label {
  font-size: 9px;
  font-family: 'galano-reg', sans-serif;
}

/* width */
.review-card-content::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.review-card-content::-webkit-scrollbar-track {
  background: var(--color-background-primary);
  box-shadow: inset 0 0 5px var(--color-background-secondary);
  border-radius: 10px;
}

/* Handle */
.review-card-content::-webkit-scrollbar-thumb {
  background: var(--color-grey-4);
  border-radius: 10px;
}

.review-card-content::-webkit-scrollbar-thumb:hover {
  background: var(--color-grey-3);
}

.review-card-body img {
  width: 28px;
  height: 28px;
}

.open-quote {
  margin-left: 20px;
}

.close-quote {
  position: absolute;
  right: 20px;
  bottom: 0;
}

/* journey card  */

.journey-card {
  width: 40%;
  max-width: 250px;
  min-width: 150px;
  z-index: 1;
  align-self: flex-end;
}

.journey-card.opposite {
  align-self: flex-start;
}

.journey-step {
  font-size: 78px;
  color: var(--color-primary-light);
  font-weight: 900;
}

.journey-card h5 {
  margin-top: -22px;
  margin-left: 20px;
}

/* upcoming event card  */
.upcoming-event-card {
  width: 100%;
  max-width: 280px;
  border: 1px solid var(--color-grey-2);
  padding: 10px;
  margin: 0 20px 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.upcoming-event-card:last-of-type {
  margin-right: 0;
}

.upcoming-event-card-img {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.upcoming-event-card img {
  width: 100%;

}

.date-avatar {
  width: 50px;
  height: 50px;
  background: var(--color-primary-dark);
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
}

.date-avatar-month {
  color: white;
  font-family: 'galano-light', sans-serif;
  margin: 0;
  font-size: 0.7rem;
  margin-bottom: 5px;
}

.date-avatar-day {
  font-family: 'galano-light', sans-serif;
  color: white;
  font-size: 1.4rem;
}

.upcoming-event-card h5 {
  margin: 16px 0 10px 0;
}

.upcoming-event-card p {
  font-size: 14px;
  margin-bottom: 12px;
}

.upcoming-event-card button {
  margin: 20px auto 10px auto;
  display: block;
}

@media only screen and (max-width: 750px) {
  .upcoming-event-card {
    margin: 0 0 20px 0;
  }
}

@media only screen and (max-width: 850px) {
  .triple-image-card {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    flex-direction: row;
  }

  .triple-image-card img {
    margin-bottom: 0;
    margin-right: 10px;
  }

  .addon-card {
    width: 48%;
  }
}

@media only screen and (max-width: 550px) {
  .double-card {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .addon-card {
    width: 100%;
  }

  .service-card .polaroid {
    display: none;
  }

  .opposite.service-card .service-card-body {
    margin-left: 0;
  }

  .how-it-works-card,
  .how-it-works-card.opposite {
    flex-direction: column;
  }

  .how-it-works-card-content {
    width: 60%;
    margin-top: -60px;
  }

  /* .why-us-card-divider {
    display: none;
  }

  .why-us-card {
    margin-bottom: 36px;
    width: 90%;
  }

  .why-us-card img {
    margin-right: 20px;
  } */
}