.thankyou {
  margin-top: 140px;
  margin: 140px auto 0 auto;
  text-align: center;
  min-height: 300px;
}

.thankyou h1 {
  color: var(--color-primary-main);
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 60px;
}

.thankyou h4 {
  color: var(--color-primary-main);
  margin-bottom: 30px;
}

.thankyou h4 a {
  font-family: 'Playfair Display', serif;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color-secondary-main);
  text-decoration: underline;
}

.thankyou .react-add-to-calendar__dropdown {
  margin: 12px auto;
}

.thankyou .list-with-icon {
  margin-top: 30px;
}

.thankyou .list-with-icon p,
.thankyou i {
  font-size: 1.3rem;
  color: var(--color-navy);
  /* color: */
}

#thankyou-baby-massage-enroll p {
  width: 90%;
  min-width: 250px;
  max-width: 540px;
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin: 30px 0;
  text-align: center;
  /* font-family: 'galano-semibold'; */
}