#team-wrapper {
  background-color: #f8f8f8;
  margin-top: 100px;
  padding-bottom: 60px;
}

#team-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.team-card-wrapper {
  width: 350px;
  height: 350px;
  position: relative;
  margin: 20px;
  cursor: pointer;
  overflow: hidden;
}

.team-card-wrapper img {
  height: 100%;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.team-card-tag {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30%;
  background-color: white;
  text-align: center;
  padding-top: 20px;
  color: #4a4a4a;
}

.team-card-tag h3 {
  color: #4a4a4a;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}

.team-card-tag h4 {
  font-weight: 300;
  margin: 0;
  color: #878787;
}

.team-card-tag.onHover h3,
.team-card-tag.onHover h4 {
  color: white;
}

.team-card-tag.onHover p {
  color: white;
  margin-top: 30px; 
  overflow: hidden;
}

.team-card-bio {
  width: 100%;
  height: 100%;
  background-color: var(--color-pink);
  position: absolute;
  top: 360px;
  left: 0;
  overflow-y: scroll;
  padding: 10%;
  transition: 0.3s all;
}

.team-card-bio.bio-show {
  top: 0;
}

.team-card-bio p {
  color: white;
  font-weight: 400;
}

@media only screen and (max-width: 400px) {
  .team-card-wrapper {
    width: 100vw;
    height: calc(.95 * 100vw);
    margin-left: 0;
    margin-right: 0;
  }  

  .team-card-bio {
    top: calc(.95 * 100vw);
  }
}