#career {
  background: #EDE8E8;
  margin-bottom: 0;
}

#career-hero {
  padding: 80px 60px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

#career h1 {
  text-align: center;
}

#career h5 {
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
  max-width: 700px;
}

#career p {
  line-height: 1.5em;
}

.background-flex-img-set {
  display: flex; 
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.background-flex-img-set img {
  width: 25%;
  height: auto;
}

.career-component {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 500px;
}

.career-component p {
  color: black;
}

.career-body {
  margin-top: 50px;
  width: 60%;
  padding: 30px;
  border-radius: 15px;
  max-width: 700px;
}

.headshot {
  object-fit: contain;
  width: 40%;
  border-radius: 15px;
}

#career-review .headshot {
  max-width: 280px;
  min-width: 200px;
}

#career-who .headshot {
  min-width: 320px;
  max-width: 400px;
}

#career-who button {
  margin-left: auto;
  margin-right: auto;
}

.asset {
  position: absolute;
  bottom: -100px;
  right: 70px;
  z-index: 1;
  width: 350px;
}

#career-who h4 {
  font-size: 1.2rem;
  letter-spacing: 0.02em;
}

#career-carousel {
  margin-top: 120px;
  padding-bottom: 100px;
}

#career-carousel h2 {
  width: 80%;
  font-size: 2.8rem;
  margin: 0 auto;
  text-align: center;
}

@media only screen and (max-width: 850px) {
  .headshot {
    position: absolute;
    top: 300px;
  }
  
  .career-body {
    min-width: 250px;
    background: #ffffff73;
    width: 65%;
    position: absolute;
  }

  #career-review .career-body {
    left: 10%;
  }

  #career-who .career-body {
    right: 10%;
    margin-top: 220px;
    z-index: 1;
    min-width: 270px;
  }

  #career-review .headshot {
    right: 10%;
    width: 60%;
  }

  #career-who .headshot {
    left: 5%;
    top: 0;
  }

  .career-component {
    height: 800px;
    overflow: show;
  }

  .asset {
    width: 50%;
    position: absolute;
    bottom: -80;
    left: 0;
    z-index: 1;
  }
}

@media only screen and (max-width: 450px) {
  .background-flex-img-set img {
    width: 50%;
  }

  #career-hero {
    padding: 40px 20px 0 20px;
  }

  #career h1 {
    font-size: 3em;
  }

  .career-component {
    height: 750px;
  }

  .career-body {
    margin-top: 0;
  }

  #career-carousel h2 {
    width: 90%;
    font-size: 2.2rem;
  }

  #career-who .headshot {
    left: 0;
  }
}
