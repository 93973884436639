#blog-post-header {
  margin-top: 100px;
  text-align: center;
}

#blog-post-header h1 {
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
  font-size: 2.5rem;
  margin-top: 20px;
}

#blog-post-header h5 {
  color: #898989;
  display: inline-block;
  font-size: 15px;
  margin-bottom: 0;
}

.br {
  margin-bottom: 25px;
}

.blog-post-body {
  padding: 30px 20% 80px 20%;
}

.blog-post-body h3 {
  font-size: 24px;
  line-height: 1.2em;
  color: black;
  margin-bottom: 20px;
  margin-top: 50px;
}

.blog-post-body h5 {
  font-family: 'galano-semibold', sans-serif;
  margin-top: 40px;
  margin-bottom: 10px;
}

.blog-post-body a {
  font-family: 'galano-semibold', sans-serif;
}

.blog-post-img {
  width: 50%;
  margin: 50px auto;
  display: block;
}

.blog-post-body p,
.blog-post-p {
  font-size: 15.5px;
  line-height: 1.5em;
  letter-spacing: .03em;
  margin-bottom: 25px;
  font-family: 'galano-light', sans-serif;
  color: var(--color-darkGray);
}

.blog-post-quote {
  width: 80%;
  text-align: center;
  margin: 50px auto;
  line-height: 1.9rem;
  letter-spacing: .03em;
  font-size: 16px;
}

.blog-post-imgFull {
  width: 100%;
  margin-bottom: 50px;
}

.blog-post-li {
  margin-top: 0px;
  margin-bottom: 0px;
}

.blog-post-video {
  position: relative;
  padding-top: 56.25%;
}

p.noMargins {
  margin: 0;
}

.separator {
  padding: 20px;
  color: #898989;
}

@media only screen and (max-width: 700px) {
  .blog-post-body {
    padding-left: 10%;
    padding-right: 10%;
  }

  .blog-post-img {
    width: 70%;
  }

  .blog-post-quote {
    width: 90%;
  }
}