.hero {
  width: 100vw;
  height: 95vh;
  position: relative;
}

.hero-body {
  position: absolute;
  top: 45%;
  left: 8%;
}

.hero-body.illustration-body {
  top: 35%;
}

.hero-illustration img {
  position: absolute;
  top: 20%;
  right: 8%;
  width: 40%;
  min-width: 180px;
  max-width: 400px;
}

.hero-body h1 {
  width: 57%;
  min-width: 250px;
  font-weight: 300;
  font-size: 1.7rem;
  line-height: 1.4em;
  color: var(--color-primary-dark);
}

.hero-body h2 {
  margin-bottom: 8px;
  color: var(--color-primary-dark);
  font-size: 2.2rem;
}

.hero-body button {
  margin-top: 20px;
  width: 40%;
  min-width: 250px;
}

.illustration-body .link {
  margin-top: 20px;
}

/* hero polaroid */

.hero-polaroid {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  width: 95%;
  position: relative;
  margin: auto;
}

.hero-polaroid .hero-body {
  width: 65%;
  position: static;
}

.hero-polaroid .polaroid {
  width: 30%;
  max-width: 300px;
  min-width: 240px;
  transform: rotate(15deg);
}

.hero-polaroid .hero-body h1 {
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 8px;
}

.hero-polaroid .hero-body h2 {
  font-size: 1.7rem;
  font-weight: 300;
}

.wave-wrap {
  position: absolute;
  width: 100%;
  /* height: 100px; */
  height: 150px;
  top: auto;
  bottom: 5vh;
  overflow: hidden;
  pointer-events: none;
}

.wave-inner {
  background: url(https://res.cloudinary.com/dhze7gimq/image/upload/v1596838632/wave-sand.png) repeat-x;
  position: absolute;
  top: auto;
  bottom: 0;
  width: 6400px;
  height: 100px;
  height: 150px;
  margin-bottom: -2px;
  animation: wave 20s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  animation-duration: 20s;
  animation-timing-function: cubic-bezier(0.36, 0.45, 0.63, 0.53);
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: wave;
  transform: translate3d(0, 0, 0);
}

.wave-wrap.footer-wave {
  height: 150px;
  transform: translateY(-100%);
  top: 0;
  bottom: auto;
}

.footer-wave .wave-inner,
.primary .wave-inner {
  background: url(https://res.cloudinary.com/dhze7gimq/image/upload/v1596869982/wave-primary-dark.png) repeat-x;
}

.secondary .wave-inner {
  background: url(https://res.cloudinary.com/dhze7gimq/image/upload/v1597185044/secondary_wave.png) repeat-x;
}

@keyframes wave {
  0% {
    margin-left: 0
  }

  100% {
    margin-left: -1600px
  }
}

@media only screen and (min-width: 1000px) {
  .hero-body h1 {
    width: calc(100vw - 400px);
  }

  .hero-body.hero-body.illustration-body h1 {
    width: calc(100vw - 600px);
  }
}

@media only screen and (max-width: 700px) {
  .hero-body h1 {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 550px) {
  .hero {
    display: flex;
    padding-top: 80px;
    align-items: center;
    flex-direction: column;
  }

  .hero-polaroid-wrapper {
    position: relative;
  }
  
  .hero.hero-illustration {
    padding-bottom: 120px;
    height: inherit;
  }
  
  .hero-polaroid {
    padding-bottom: 200px;
    display: flex;
    flex-direction: column-reverse;
    height: inherit;
  }

  .hero-polaroid .hero-body {
    margin-top: 40px;
    width: 100%;
  }

  .hero-polaroid .polaroid {
    /* display: ; */
    position: initial;
    width: 220px;
    height: 220px;
    min-width: 0;
    margin: 0;
  }

  .hero-polaroid .polaroid img {
    width: calc(100% - 48px);
    height: auto;
  }

  .hero-polaroid .polaroid .polaroid-footer {
    display: none;
  }

  .hero-body {
    top: 55%;
    text-align: center;
  }

  .hero-body.illustration-body {
    position: static;
    text-align: center;
    margin-top: 40px;
  }

  .hero-body h1 {
    width: 95%;
    margin: auto;
  }

  .hero-illustration img {
    position: static;
  }

  .hero-polaroid-wrapper .wave-wrap {
    display: none;
  }

  .hero-illustration-wrapper .wave-wrap {
    display: none;
  }
}