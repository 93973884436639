#checkout {
  margin: 100px auto;
  max-width: 450px;
  width: 90%;
}

#checkout h3 {
  font-size: 1.4rem;
}

#checkout p {
  margin-bottom: 0;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}

.item-img {
  width: 45px;
  height: 45px;
}

.item-title {
  width: calc((100% - 100px) * 0.9); 
  margin-bottom: 0;
}

.promo-code {
  padding: 0 30px 30px 30px;
}

.promo-code p {
  margin: 0;
  font-size: 14px;
}

.promo-code .item {
  padding: 0;
}

.promo-code .ui.input {
  width: 200px;
}

#promo-code-clickable {
  cursor: pointer;
  padding: 0 30px 30px 30px;
}

#promo-code-clickable:hover {
  text-decoration: underline;
}

#promo-code-input {
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-radius: 0;
  margin-right: 10px;
  margin-left: 10px;
  padding-left: 12px;
}

#promo-code-button {
  border-radius: 0;
  padding: 12px 10px;
  letter-spacing: 0.5px;
  margin-right: 0;
  font-size: 13px;
}

.promo-error {
  color: red;
  margin-top: 5px;
  font-size: 13px;
}

#payment {
  margin-top: 50px;
}

#payment form {
  padding: 30px;
  display: flex;
  flex-direction: column;
}

#payment button {
  margin-top: 30px;
}

#card-element {
  padding: .67857143em 1em;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: .28571429rem;
}

.payment-footer {
  padding: 20px 0;
  background-color: var(--color-primary-dark);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.payment-footer p {
  color: white;
}

.payment-type {
  margin-top: 10px;
  display: flex; 
  justify-content: space-between;
  align-items: flex-start;
  width: 160px;
}

.payment-type img {
  width: 35px;
  object-fit: contain;
}

#payment .list-with-icon {
  margin-bottom: 10px;
}

#payment .list-with-icon p,
#payment .list-with-icon i {
  color: var(--color-lightGray);
  font-size: 15px;
}