.polaroid {
  height: 400px;
  width: 300px;
  margin-left: -30px;
  border: 1px solid var(--color-primary-dark);
  background-color: var(--color-background-primary);
  flex-shrink: 0;
  position: relative;
}

.polaroid img {
  object-fit: cover;
  width: 90%;
  display: block;
  margin: 24px auto;
  max-height: 260px;
  overflow: hidden;
}

.polaroid-footer h5 {
  font-family: 'Nothing You Could Do', cursive;
  color: var(--color-primary-dark);
  margin: 0;
  width: 75%;
  transform: rotate(-15deg);
  position: absolute;
  bottom: 45px;
  right: 10px;
  font-size: 1.2rem;
}

@media only screen and (max-width: 750px) {
  .polaroid {
    height: 320px;
    width: 240px;
  }
}