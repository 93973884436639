#mama-experience.landing-component {
  min-height: 400px;
}

#mama-experience .landing-component-left {
  padding-left: 10%;
  align-items: start;
}

#mama-experience img {
  width: 50%;
}

@media only screen and (max-width: 880px) {
  #mama-experience h2 {
    font-size: 2.3rem;
    max-width: 350px;
  }

  #mama-experience h5 {
    max-width: 400px;
  }
}

@media only screen and (max-width: 670px) {
  #mama-experience .landing-component-left {
    width: 80%;
    padding-left: 0;
    align-items: center;
  }

  #mama-experience h2 {
    text-align: center;
  }
}